<template>
  <div>
    <van-nav-bar fixed border left-arrow
                 title="工单列表"
                 left-text="返回"
                 @click-left="router.back"/>
    <van-overlay :show="loadingOfWorkorderList || loadingOfDispatchWorkorder || loadingOfPrepareWorkorder">
      <div class="wrapper" @click.stop>
        <van-loading type="spinner" color="#1989fa"/>
      </div>
    </van-overlay>
    <div class="page-container">
      <div class="card" v-for="item in list.dispatchData" :key="item.id"
           @click="onDispatchWorkorderClick(item.id)">
        <ul>
          <li>
            <div style="color: #f05654">此工单待指派中</div>
          </li>
          <li class="workorder-info">
            <div>
              电话: {{ item.clientCellphone }}
            </div>
            <div>
              时间: {{ item.createTime }}
            </div>
          </li>
        </ul>
      </div>
      <div class="card" v-for="item in list.list" :key="item.id"
           @click="onPrepareWorkorderClick(item.id)">
        <div class="handle">
          <van-icon name="manager-o" size="30" color="#999"/>
          <span class="title">{{ item.handlerName }}</span>
        </div>
        <ul>
          <li class="number">
            <div>ID:{{ item.id }}</div>
          </li>
          <li class="workorder-info">
            <div>
              电话: {{ item.clientCellphone }}
            </div>
            <div>
              时间: {{ item.createTime }}
            </div>
          </li>
        </ul>
      </div>
      <van-list
          v-model:loading="list.loading"
          :finished="list.finished"
          :error.sync="list.error"
          :immediate-check="false"
          :offset="30"
          error-text="加载失败，点击重新加载"
          finished-text="没有更多了"
          @load="onLoad">
        <div class="card" v-for="item in list.workorderList" :key="item.id" @click="onWorkorderItemClick(item.id)">
          <div class="handle">
            <van-icon name="manager-o" size="30" color="#999"/>
            <span class="title">{{ item.handlerName }}</span>
          </div>
          <ul>
            <li class="number">
              <div>ID:{{ item.id }}</div>
            </li>
            <li class="workorder-info">
              <div>
                电话: {{ item.clientCellphone }}
              </div>
              <div>
                时间: {{ item.createTime }}
              </div>
            </li>
          </ul>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  name: "Workorders"
};
</script>
<script setup>
import {onMounted, reactive, ref} from "vue";
import {useRouter} from "vue-router";
import {requestPrepareWorkorder, queryAllDispatchWorkorder, workorderList} from "@/api/workorder";

const router = useRouter();
const urlParam = getUrlParam();
const list = reactive({
  loading: false,
  error: false,
  finished: false,
  current: 1,
  pageSize: 10,
  total: 0,
  list: [],
  dispatchData: [],
  workorderList: []
});
const loadingOfWorkorderList = ref(false);
const loadingOfDispatchWorkorder = ref(false);
const loadingOfPrepareWorkorder = ref(false);

const onLoad = () => {
  list.current++;
  requestAllWorkorder();
};

// 1：创建；  5：已分配； 9：领取； 13：开始处理；14：挂起; 17：完成； 21：关闭
const progress = (data) => {
  switch (data) {
    case 1:
      return "待分配";
    case 5:
      return "待领取";
    case 9:
      return "待处理";
    case 13:
      return "处理中";
    case 14:
      return "挂起中";
    case 17:
      return "已完成";
    default:
      return "待处理";
  }
};

const requestPrepareWorkorderList = () => {
  const params = {
    clientCellphone: urlParam.cellphone
  };
  loadingOfPrepareWorkorder.value = true;
  requestPrepareWorkorder(params).then(res => {
    list.list = res.data.records;
    loadingOfPrepareWorkorder.value = false;
  }).catch(err => {
    loadingOfPrepareWorkorder.value = false;
  });
};

const requestDispatchWorkorder = () => {
  const params = {
    converted: 0,
    clientCellphone: urlParam.cellphone
  };
  console.log(params);
  loadingOfDispatchWorkorder.value = true;
  //查询指定手机号所有待指派工单
  queryAllDispatchWorkorder(params).then(res => {
    loadingOfDispatchWorkorder.value = false;
    list.dispatchData = res.data;
  }).catch(err => {
    loadingOfDispatchWorkorder.value = false;
  });
};

const requestAllWorkorder = () => {
  const params = {
    current: list.current,
    pageSize: list.pageSize,
    clientCellphone: urlParam.cellphone
  };
  loadingOfWorkorderList.value = true;
  workorderList(params).then(res => {
    console.log(res);
    const rowList = res.data.records;
    list.loading = false;
    list.total = res.data.total;
    if (rowList === null || rowList.length === 0) {
      list.finished = true;
      loadingOfWorkorderList.value = false;
      return;
    }
    list.workorderList = list.workorderList.concat(rowList);
    if (list.workorderList.length >= list.total) {
      loadingOfWorkorderList.value = false;
      list.finished = false;
    }
    loadingOfWorkorderList.value = false;
  }).catch(err => {
    loadingOfWorkorderList.value = false;
  });
};


const onDispatchWorkorderClick = (id) => {
  urlParam.workorderId = id;
  router.push({path: "/unassigned-workorder", query: urlParam});
};

const onPrepareWorkorderClick = (id) => {
  urlParam.workorderId = id;
  router.push({path: "/prepare-workorder", query: urlParam});
};

const onWorkorderItemClick = (id) => {
  urlParam.workorderId = id;
  router.push({path: "/workorder", query: urlParam});
};

onMounted(() => {
  validationUrlParam();
  if (!getUrlParam().cellphone) {
    router.back();
  }
  requestPrepareWorkorderList();
  requestDispatchWorkorder();
  requestAllWorkorder();
});
</script>

<style scoped>

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.card {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 15px;
  margin: 24px;
  border-radius: 8px;
  box-shadow: 0 5px 11px -3px rgba(0, 0, 0, .5);
}

.handle {
  flex: 2;
  text-align: center;
  border-right: 1px solid #999;
}

.handle img {
  width: 30px;
}

.title {
  display: inline-block;
  width: 80px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 2px;
}

.number {
  font-size: 18px;
  color: #0548ef;
}

.card ul {
  flex: 8;
  margin-left: 10px;
}

.card ul li {
  height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
}

.card ul li span {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  color: dimgray;
}

.workorder-info {
  margin-top: 13px;
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  color: #333;
}
</style>
